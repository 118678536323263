const SELECT_MENU = 'app/SELECT_MENU';
const ALERT_BAR_SHOW = 'app/SNACK_BAR_SHOW';
const USERNAME = 'app/USERNAME';
const SET_BALANCE = 'app/SET_BALANCE';

function selectMenu(menu) {
    return {
        type: SELECT_MENU,
        payload: {
            selectMenu: menu
        }
    }
}

function alertBar(data) {
    return {
        type: SELECT_MENU,
        payload: {
            ...data
        }
    }
}


function setUsername(data) {
    return {
        type: USERNAME,
        payload: {
            username: data
        }
    }
}

function setBalnce(data) {
    return {
        type: SET_BALANCE,
        payload: {
            balance: data
        }
    }
}

export {
    SELECT_MENU,
    ALERT_BAR_SHOW,
    USERNAME,
    SET_BALANCE,
    selectMenu,
    alertBar,
    setUsername,
    setBalnce
}