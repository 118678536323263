import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Redux
// import * as appActions  from "./../../@core/redux/actions/app/index";

export default function AppHeader() {
    const balance = useSelector(state => state.app.balance);

    return (
        <>
            <div className="appHeader">
                <div className="container">
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className="flexLogo">
                                ULTRON
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="btnAccount chakra">
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item className="borderAccountRight">
                                        <div className="textAccountPadding">
                                            BALANCE
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="textAccountPadding textAccountColor">
                                            { balance } <span className="textAccountUnit roboto">฿</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}