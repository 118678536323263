import { SELECT_MENU, ALERT_BAR_SHOW, USERNAME, SET_BALANCE }  from './../../actions/app/index';

const initialState = {
    selectMenu: window.location.pathname,
    alertData: {
        statusShow: false,
        statusAlert: "",
        titleAlert: "",
        textAlert: ""
    },
    username: "",
    balance: 0
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SELECT_MENU:
            return { ...state, selectMenu: action.payload.selectMenu };
        case ALERT_BAR_SHOW:
            return { ...state, alertData: action.payload.alertData };
        case USERNAME:
            return { ...state, username: action.payload.username };
        case SET_BALANCE:
            return { ...state, balance: action.payload.balance };
        default: 
            return state;
    } 
}