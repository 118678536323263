import './App.css';

// Component
import Roters from './views/Routers';

function App() {
  return (
    <div className="App">
      <Roters></Roters>
    </div>
  );
}

export default App;
