import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';


// Component
import AppHeader from "./components/AppHeader";

// Pages
const HomeApp = lazy(() => import("./pages/home/index"));
const GameApp = lazy(() => import("./pages/game/index"));

const Roters = () => {
    return (
        <div className="appContent appApiTest">
            <AppHeader></AppHeader>
            <Router>
                <Suspense fallback={null}>
                    <Switch>
                        <Route path="/:page/:username">
                            <HomeApp></HomeApp>
                        </Route>

                        <Route path="/game">
                            <GameApp></GameApp>
                        </Route>
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}

export default Roters;